import React from "react"
import "@fontsource/montserrat"
import { Link, useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import {
    CollectionsContainer,
    CardWrapper,
    Title,
    CardImage
} from'./lib/collections.module.css'

const Collections = () => {

    const data = useStaticQuery(graphql`
    query {
        allMdx {
            nodes {
                frontmatter {
                    title
                    thumb {
                        childImageSharp {
                            gatsbyImageData (
                            placeholder: TRACED_SVG
                            quality: 100
                            transformOptions: {cropFocus: CENTER, fit: COVER}
                            formats: WEBP
                            width: 720
                            layout: CONSTRAINED
                            )
                        }
                    }
                }
                id
                slug
            }
        }
    }
    `)

    return (
        <div className={CollectionsContainer}>
        {
        data.allMdx.nodes.map(node => (
            <article className={CardWrapper} key={node.id}>
                <Link to={`/collections/${node.slug}`}>
                    <span className={Title}>{node.frontmatter.title}</span>
                    <GatsbyImage alt={node.frontmatter.title} image={getImage(node.frontmatter.thumb)} className={CardImage} />
                </Link>
            </article>
            ))
        }
        </div>
    )
}

export default Collections