import * as React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from '../components/layout'
import StandardHero from '../components/homepage/standard-hero'
import Collections from '../components/collections'
import Services from '../components/homepage/services'
import "@fontsource/montserrat"
import "@fontsource/allura"
import {
  containerText
} from'../lib/home.module.css'
import {
  buttonsContainer,
  buttonsContainerDark
} from'../lib/buttons.module.css'

const IndexPage = () => {
  return (
    <Layout pageTitle="Curtain Poles - Byron and Byron">
      
      <StandardHero />

      <div className={buttonsContainer}>
        <div><Link to="/map">Find nearest stockist</Link></div>
        <div><AnchorLink to="#contact" title="Get in touch">Get in touch</AnchorLink></div>
      </div>
      
      <Collections />

      <div className={containerText}>
        <p>Byron and Byron was founded in 1984, we are a leading British curtain pole designer and manufacturer passionate about our products that are all hand finished here in London by our team of talented craftspeople.</p>
        <p>Supplying bespoke handmade curtain poles and accessories, we stock everything from bay poles, tracked, corded and uncorded. As well as our latest phenomenon Cov-a-Blind©, the perfect blind solution you always knew you needed.</p>
        <p>We pride ourselves on the details and our customer's experience is at the forefront every step of the way; from conception, to prototypes, to the final manufacturing of each component.</p>
      </div>

      <div className={buttonsContainerDark}>
        <div><Link to="/collections">View Collections</Link></div>
        <div><Link to="/bespoke">Bespoke Services</Link></div>
      </div>
      
      <Services /> 

      <div className={containerText}>
        <p>Please note that our website is for reference only and not an e-commerce site. To set up a trade account or to find stockists and distributors wherever you are, please contact us.</p>
      </div>

      <div className={buttonsContainerDark}>
        <div><a href="mailto:sales@byronandbyron.com ">Get in touch</a></div>
        <div><Link to="/map">Find a Stockist</Link></div>
      </div>

   </Layout>
  )
}

export default IndexPage