import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
    heroContainer,
    heroImage
} from'./lib/hero.module.css'

const StandardHero = () => {

  return (
    <div className={heroContainer}>
      <StaticImage
                className={heroImage}
                layout="fullWidth"
                alt=""
                src={"../../images/home/hero.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
      />
    </div>
  );
}

export default StandardHero