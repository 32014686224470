import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import {
    servicesContainer,
    contentArea,
    servicesImage
} from'./lib/hero.module.css'

const Services = () => {

  return (
    <div className={servicesContainer}>
      <div className={contentArea}>
        <h2>
          <Link to="/bespoke">
            Bespoke Services
          </Link> 
        </h2>
        <Link to="/bespoke#baypoles">Bay Poles</Link> 
        <Link to="/bespoke#corded">Corded</Link>
        <Link to="/bespoke#painted">Painted</Link>
      </div>
      <StaticImage
                className={servicesImage}
                layout="fullWidth"
                alt="Services"
                src={"../../images/home/baypole.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
      />
    </div>
  );
}

export default Services